const express = require('express');
const path = require('path');
const router = express.Router();
const winston = require('winston');


router.get('/', (req, res) => {

  try{

    res.sendFile(path.join(__dirname, '../typesense-instantsearch-demo/index.html'));

  }catch(err){
    // console.error(err);
    winston.error(err.message, err);
    return res.status(500).send('Internal Server Error');
  };

});


module.exports = router;

